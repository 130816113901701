import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import dayjs from 'dayjs'

import { theme } from '@/styles/theme'

import { Layout } from '@/components/layouts/Layout'
import { Title } from '@/components/elements/Title'
import { BreadCrumbs } from '@/components/layouts/Breadcrumbs'
import { Seo } from '@/components/Seo'
import type { NoticeQuery } from '@/interfaces/notice'
import { ListItems } from '@/components/layouts/ListItems'
import { MainWrapper, TitleWrapper } from '@/styles/common'
import { Button } from '@/components/elements/Button'

export const query = graphql`
  query {
    allMicrocmsNotice (sort: { fields: start, order: DESC }) {
      edges {
        node {
          notice
          noticeId
          start
          end
          content
          display
        }
      }
    }
  }
`

type Query = {
  allMicrocmsNotice: NoticeQuery
}

export default function notice({ data }: { data: Query }): JSX.Element {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.allMicrocmsNotice.edges.slice(indexOfFirstItem, indexOfLastItem);
  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const breadCrumbs = [
    {
      name: 'トップ',
      path: '/',
    },
    {
      name: ' お知らせ',
    },
  ]
  return (
    <Layout>
      <Seo
        title="お知らせ"
        canonical="https://su-pay.jp/notices/"
        description="SU-PAYについてのお知らせはこちら。"
      />
      <Title as="h1" bg={true}>
        お知らせ
      </Title>
      <MainWrapper>
        <BreadCrumbs breadCrumbs={breadCrumbs} />
        <TitleWrapper>
          <Title as="h2" color={theme.colors.supayBlue}>
            お知らせ一覧
          </Title>
        </TitleWrapper>
        <ul>
          {currentItems.map((notice) => (
            <ListItems
              key={notice.node.noticeId}
              link={`/notices/${notice.node.noticeId}/`}
              item={notice.node.notice}
              showBadge={false}
              date={dayjs(notice.node.start).format('YYYY/MM/DD(dd)')}
            />
          ))}
        </ul>

        <PageButton>
          {currentPage > 1 && (
            <Button onClick={prevPage}>前へ</Button>
          )}
          {data.allMicrocmsNotice.edges.length > indexOfLastItem && (
            <Button onClick={nextPage}>次へ</Button>
          )}
        </PageButton>
      </MainWrapper>
    </Layout>
  )
}

const PageButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;

  button {
    margin-right: 10px;
    width: 120px;
  }

  @media screen and (max-width: 1047px) {
    button {
      width: 94px;
    }
  }
  @media screen and (max-width: 450px) {
    button {
      width: 90px;
      font-size: 18px;
    }
  }

  @media screen and (max-width: 370px) {
    button {
      width: 88px;
      height: 50px;
      font-size: 16px;
    }
  }
`
